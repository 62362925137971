import { ThirdId } from "../api/maestro/maestro.typings";
import { FetchFn } from "@sg-widgets/react-core";
import { fetchMandates } from "../api/mandate/mandate.api";
import { ContactId } from "../common/typings/contacts.typings";
import { Mandate } from "../common/typings/mandate.typing";
import React from "react";
import { MandateSearchParams } from "../api/mandate/mandate.typings";

export const useMandateList = (
  fetch: FetchFn,
  contactId: ContactId,
  thirdId: ThirdId,
  accountId: string,
  { businessFunctionId, roleId, delegationId, contactEmail, contactPerimeter, roleItemIds }: MandateSearchParams
): [Mandate[], boolean, string | null] => {
  const [mandate, setMandates] = React.useState<Mandate[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isInit, setIsInit] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsInit(false);
  }, []);

  React.useEffect(() => {
    if (thirdId || contactId || contactEmail || delegationId || accountId) {
      setIsLoading(true);
      fetchMandates(fetch, contactId, thirdId, accountId, { businessFunctionId, roleId, delegationId, contactEmail, contactPerimeter, roleItemIds })
        .then(mandates => {
          setError(null);
          setMandates(mandates);
        })
        .catch(error => setError(error?.userMessage ?? error?.message ?? "Failed to fetch mandates"))
        .finally(() => setIsLoading(false));
    }
  }, [contactId, thirdId, businessFunctionId, roleId, contactEmail, delegationId, accountId, contactPerimeter]);

  const loading = isLoading || isInit;

  return [mandate, loading, error];
};
